<template>
    <v-card
        outlined
        :to="this.isLoggedIn ? { name: 'reportsWallet', params: { address: wallet.address, coin: wallet.coin }} : '#'">
        <v-card-text>
            <v-row align="center">
                <v-col cols="auto">
                    <v-avatar
                        class="mr-1"
                        size="40">
                        <img
                            :src="getLogo(wallet.coin)"
                            :alt="wallet.coin">
                    </v-avatar>
                    <p class="caption text-center mb-0 mt-1">
                        {{$_.toUpper(wallet.coin)}}
                    </p>
                </v-col>
                <v-col>
                    <p class="mb-0">
                        <span class="caption">Name:</span> {{wallet.name}}
                    </p>
                    <p class="mb-0">
                        <span class="caption">Address:</span> <code>{{wallet.address}}</code>
                    </p>
                </v-col>
                <v-col cols="auto">
                    <v-btn icon>
                        <v-icon>
                            mdi-chevron-right
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        props: {
            wallet: {
                required: true,
                type: Object
            }
        },
        data() {
            return {
                expand: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-card:hover {
        background-color: #262626;
        border: thin solid rgba(255, 255, 255, 0.18)
    }
</style>