<template>
	<v-container>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="6725597086"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
		<v-row>
			<v-col>
				<v-card>
					<v-card-title>
                        <v-row
                            dense
                            align="center">
                            <v-col cols="auto">
                                <v-icon class="mr-2">
                                    mdi-chart-areaspline
                                </v-icon>
                                Reports
                            </v-col>
                            <v-spacer>
                            </v-spacer>
                            <v-col cols="auto">
                                <p class="mb-0 caption">
                                    Filter by coin:
                                </p>
                            </v-col>
                            <v-col cols="4">
                                <v-select
                                    v-model="filterCoin"
                                    :items="coins"
                                    multiple
                                    hide-details
                                    dense
                                    outlined
                                    item-text="name"
                                    item-value="slug"
                                    :menu-props="{ offsetY: true }">
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip
                                            small
                                            close
                                            @click:close="filterCoin.splice(index, 1)">
                                            <v-avatar
                                                class="mr-2"
                                                size="20">
                                                <img
                                                    :src="getLogo(item.slug)"
                                                    :alt="item.slug">
                                            </v-avatar>
                                            {{$_.toUpper(item.slug)}}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <v-avatar
                                            class="mr-2"
                                            size="20">
                                            <img
                                                :src="getLogo(item.slug)"
                                                :alt="item.slug">
                                        </v-avatar>
                                        {{item.name}}
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
					</v-card-title>
					<v-card-text>
                        <v-container>
                            <v-row>
                                <p>
                                    {{isLoggedIn ? 'Select a wallet to view a report.' : 'Please login to view a report.'}}
                                </p>
                            </v-row>
                        </v-container>
                        <v-row
                            v-for="(wallet, key) in filteredWallets"
                            :key="key">
                            <v-col>
                                <item-wallet :wallet="wallet">
                                </item-wallet>
                            </v-col>
                        </v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
    import Ad from '../components/ads/GeneralAd'
    import ItemWallet from '../components/reports/ItemWallet'

    export default {
        metaInfo: {
            titleTemplate: 'Wallets - %s',
            meta: [
                { name: 'description', content: 'Set your wallet address for Monero XMR and Masari MSR coins.' },
            ]
        },
        data() {
            return {
                view: 0,
                filterCoin: []
            }
        },
        computed: {
            filteredWallets() {
                let _this = this;
                if (this.filterCoin.length) {
                    return this.$_.filter(this.walletList, function(o) {
                        return _this.$_.includes(_this.filterCoin, o.coin);
                    });
                }
                return this.walletList;
            }
        },
        components: {
            Ad,
            ItemWallet
        },
        created() {
            // console.log(this.coins)
        }
    }
</script>

<style lang="scss" scoped>
    code { word-break: break-all }
</style>